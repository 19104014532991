<template>
  <div>
    <v-card-title>
      <v-text-field
        v-model="search"
        clearable
        append-icon="mdi-magnify"
        :label="$t('SEARCH')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions: rowsPerPageItems,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right'
      }"
    >
      <template v-slot:item.name="{ item }">
        <strong>{{ item.warehouseName }}</strong> | {{ item.name }}
        <small>({{ item.unitShortName }})</small>
      </template>
      <template v-slot:item.currentStock="{ item }">
        <strong>{{ item.currentStock }}</strong>
        <small> ({{ item.unitShortName }})</small>
      </template>
      <template
        v-slot:item.stockPurchaseOrderRequestedItem.packageValue="{ item }"
      >
        <strong>{{ item.stockPurchaseOrderRequestedItem.packageValue }}</strong>
        <small> ({{ item.packageName }})</small>
      </template>
      <template
        v-slot:item.stockPurchaseOrderRequestedItem.unitValue="{ item }"
      >
        <strong>{{ item.stockPurchaseOrderRequestedItem.unitValue }}</strong>
        <small> ({{ item.unitShortName }})</small>
      </template>
      <template v-if="showTotalPrice" slot="body.append">
        <tr class="previewTr">
          <th colspan="100%">
            {{ $t("TOTAL_PRICE") }}:
            {{ itemsTotal }}
          </th>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
import _ from "lodash";
export default {
  name: "StockPurchaseOrderPreviewItemsTable",
  inheritAttrs: false,
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      }
    },
    items: {
      type: Array,
      default: function () {
        return [];
      }
    },
    itemsTotal: {
      type: Array,
      default: function () {
        return [];
      }
    },
    showTotalPrice: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      search: "",
      rowsPerPageItems: [-1, 100, 10]
    };
  },
  components: {},
  beforeMount() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    findInSelect(id, options) {
      console.log(options);
      console.log(id);
      let item = _.find(options, { id: id });
      if (item) {
        return item.name;
      } else {
        return "-";
      }
    }
  }
};
</script>
