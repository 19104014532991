<template>
  <v-stepper v-model="actionParams.wizardStep">
    <v-stepper-header>
      <v-stepper-step :complete="actionParams.wizardStep > 1" step="1">
        {{ $t("STEP") }} 1
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="actionParams.wizardStep > 2" step="2">
        {{ $t("STEP") }} 2
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="actionParams.wizardStep > 3" step="3">
        {{ $t("STEP") }} 3
      </v-stepper-step>
      <v-divider></v-divider>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <StepStockPurchaseOrderSelectStore
          :firstLoader.sync="tempFirstLoader"
          :doGetItems.sync="doGetItems"
          :wizardStep.sync="actionParams.wizardStep"
          :globalForDate.sync="globalForDate"
          :items.sync="items"
          :selectedFromStore.sync="selectedFromStore"
          :selectedToStore.sync="selectedToStore"
          :localStorageSelectedItems="localStorageSelectedItems"
        >
        </StepStockPurchaseOrderSelectStore>
      </v-stepper-content>
      <v-stepper-content step="2">
        <StepStockPurchaseOrderSetItems
          :firstLoader.sync="tempFirstLoader"
          :wizardStep.sync="actionParams.wizardStep"
          :globalForDate.sync="globalForDate"
          :notes.sync="notes"
          :items.sync="items"
          :fromStore="selectedFromStore"
          :toStore="selectedToStore"
          :headers="headers"
          :selectedItems="selectedItems"
          :showTotalPrice="false"
        >
        </StepStockPurchaseOrderSetItems>
      </v-stepper-content>
      <v-stepper-content step="3">
        <StepStockPurchaseOrderPreview
          :firstLoader.sync="tempFirstLoader"
          :doGetItems.sync="doGetItems"
          :wizardStep.sync="actionParams.wizardStep"
          :notes.sync="notes"
          :selectedFromStore.sync="selectedFromStore"
          :selectedToStore.sync="selectedToStore"
          :headers="headers"
          :selectedItems="selectedItems"
          :showTotalPrice="false"
        >
        </StepStockPurchaseOrderPreview>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import StepStockPurchaseOrderSelectStore from "@/modules/stock/components/purchase-orders/StepStockPurchaseOrderSelectStore.vue";
import StepStockPurchaseOrderSetItems from "@/modules/stock/components/purchase-orders/StepStockPurchaseOrderSetItems.vue";
import StepStockPurchaseOrderPreview from "@/modules/stock/components/purchase-orders/StepStockPurchaseOrderPreview.vue";

import _ from "lodash";
import moment from "moment";
export default {
  name: "StockPurchaseOrderCreateStepper",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      doGetItems: true,
      actionParams: {
        wizardStep: 1
      },
      globalForDate: moment().format("YYYY-MM-DD"),
      notes: "",
      items: [],
      localStorageSelectedItems: [],
      selectedFromStore: {},
      selectedToStore: {},
      headers: [
        {
          text: this.$t("ITEM"),
          align: "start",
          filterable: true,
          value: "name"
        },
        {
          text: this.$t("CODE"),
          value: "code",
          sortable: true
        },
        {
          text: this.$t("CURRENT_STOCK"),
          value: "currentStock",
          sortable: true
        },
        {
          text: this.$t("RECEIVE"),
          value: "stockPurchaseOrderRequestedItem.packageValue",
          sortable: true
        },
        {
          text: this.$t("RECEIVE"),
          value: "stockPurchaseOrderRequestedItem.unitValue",
          sortable: true
        }
      ]
    };
  },
  components: {
    StepStockPurchaseOrderSelectStore,
    StepStockPurchaseOrderSetItems,
    StepStockPurchaseOrderPreview
  },
  created() {
    let vm = this;

    let stockPurchaseOrderSelectedItems =
      JSON.parse(localStorage.getItem("stockPurchaseOrderSelectedItems")) ?? [];

    vm.localStorageSelectedItems = stockPurchaseOrderSelectedItems;
  },
  computed: {
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    selectedItems() {
      let vm = this;
      let selectedItems = [];
      if (_.size(vm.items) > 0) {
        vm.items.forEach(category => {
          let filteredItems = category.items.filter(function(item) {
            return item.stockPurchaseOrderRequestedItem.unitValue > 0;
          });
          selectedItems = [...selectedItems, ...filteredItems];
        });
      }

      localStorage.setItem(
        "stockPurchaseOrderSelectedItems",
        JSON.stringify(selectedItems)
      );
      return selectedItems;
    }
  },
  watch: {},
  methods: {
    updateWizardStep(step) {
      let vm = this;
      vm.actionParams.wizardStep = step;
    }
  }
};
</script>
