<template>
  <div>
    <b-row>
      <StockInfoPanels
        v-if="!firstLoader"
        :fromStoreName="fromStore.name"
        :toStoreName="toStore.name"
      >
      </StockInfoPanels>
    </b-row>
    <v-divider></v-divider>
    <b-row>
      <b-col :sm="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel
            v-for="(warehouse, index) in tempItems"
            v-bind:key="'warehouse-' + index"
            class="my-2"
          >
            <v-expansion-panel-header class="info text-white">
              <h4>
                {{ warehouse.name
                }}<small>({{ _.size(warehouse.items) }})</small>
              </h4>
              <template v-slot:actions>
                <v-icon color="white"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  clearable
                  append-icon="mdi-magnify"
                  :label="$t('SEARCH')"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="warehouse.items"
                :search="search"
              >
                <template v-slot:item.name="{ item }">
                  {{ item.name }}<br />
                  <p>
                    <small
                      >({{ item.packageName }} - {{ item.unitValue
                      }}{{ item.unitShortName }} )</small
                    >
                  </p>
                </template>
                <template
                  v-slot:item.stockPurchaseOrderRequestedItem.packageValue="{
                    item
                  }"
                >
                  <v-text-field
                    class="my-5"
                    v-model="item.stockPurchaseOrderRequestedItem.packageValue"
                    hide-details
                    type="number"
                    :step="1"
                    min="0"
                    hide-spin-buttons
                    @keydown.native="preventArrowKeys"
                    @focus="$event.target.select()"
                    @blur="filterPackageValue(item)"
                  >
                    <template v-slot:append>
                      ({{ item.packageName }})
                    </template>
                  </v-text-field>
                </template>
                <template
                  v-slot:item.stockPurchaseOrderRequestedItem.unitValue="{
                    item
                  }"
                >
                  <v-text-field
                    class="my-5"
                    v-model="item.stockPurchaseOrderRequestedItem.unitValue"
                    hide-details
                    type="number"
                    :step="item.unitNumberType == 2 ? 1 : 0.001"
                    min="0"
                    hide-spin-buttons
                    @keydown.native="preventArrowKeys"
                    @focus="$event.target.select()"
                    @blur="filterUnitValue(item)"
                  >
                    <template v-slot:append>
                      ({{ item.unitShortName }})
                    </template>
                  </v-text-field>
                </template>
                <template v-slot:item.currentStock="{ item }">
                  {{ item.currentStock }} ({{ item.unitShortName }})
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </b-col>
      <b-col>
        <v-textarea
          name="stock-action-notes"
          :label="$t('NOTES')"
          auto-grow
          rows="1"
          prepend-icon="mdi-comment"
          v-model="tempNotes"
        ></v-textarea>
      </b-col>
    </b-row>
    <b-button
      :disabled="_.size(selectedItems) == 0"
      class="mr-3"
      variant="primary"
      @click="updateWizardStep(3)"
      >{{ $t("CONTINUE") }}</b-button
    >
    <b-button
      class="mr-3"
      variant="outline-primary"
      @click="updateWizardStep(1)"
      >{{ $t("BACK") }}</b-button
    >
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

import StockInfoPanels from "@/modules/stock/components/StockInfoPanels.vue";
import _ from "lodash";
export default {
  name: "StepStockPurchaseOrderSetItems",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    wizardStep: {
      type: Number,
      default: 1
    },
    notes: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    },
    headers: {
      type: Array,
      default: function() {
        return [];
      }
    },
    selectedItems: {
      type: Array,
      default: function() {
        return [];
      }
    },
    fromStore: {
      type: Object,
      default: function() {
        return {};
      }
    },
    toStore: {
      type: Object,
      default: function() {
        return {};
      }
    },
    showTotalPrice: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      panel: 0,
      search: ""
    };
  },
  components: { StockInfoPanels },
  beforeMount() {},
  mounted() {},
  computed: {
    ...mapGetters(["isLoadingStock", "isLoadingSharedStore", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempNotes: {
      get: function() {
        return this.notes;
      },
      set: function(newValue) {
        this.$emit("update:notes", newValue);
      }
    },
    tempItems: {
      get: function() {
        return this.items;
      },
      set: function(newValue) {
        this.$emit("update:items", newValue);
      }
    }
  },
  watch: {},
  methods: {
    preventArrowKeys(event) {
      // Check if the pressed key is an arrow key
      if (["ArrowUp", "ArrowDown"].includes(event.key)) {
        event.preventDefault();
      }
      if (event.key === "," && !event.target.value.includes(".")) {
        event.preventDefault();
        event.target.setAttribute("type", "text");
        event.target.value = event.target.value + ".0";
        event.target.setSelectionRange(
          event.target.value.length - 1,
          event.target.value.length
        );
        event.target.setAttribute("type", "number");
      }
    },
    filterUnitValue(item) {
      item.stockPurchaseOrderRequestedItem.unitValue = this.formatItemField(
        item,
        Math.abs(item.stockPurchaseOrderRequestedItem.unitValue)
      );
      item.stockPurchaseOrderRequestedItem.packageValue = Math.abs(
        item.stockPurchaseOrderRequestedItem.unitValue / item.unitValue
      );
    },
    filterPackageValue(item) {
      item.stockPurchaseOrderRequestedItem.packageValue = this.formatItemField(
        item,
        Math.abs(item.stockPurchaseOrderRequestedItem.packageValue)
      );
      item.stockPurchaseOrderRequestedItem.unitValue = this.formatItemField(
        item,
        Math.abs(
          item.stockPurchaseOrderRequestedItem.packageValue * item.unitValue
        )
      );
    },
    updateWizardStep(wizardStep) {
      this.$emit("update:wizardStep", wizardStep);
    },
    formatItemField(item, value) {
      if (item.unitNumberType == 2) {
        value = Math.round(value);
      } else {
        value = this.$options.filters.formatUnitValue(value);
      }
      return value;
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    handleInput(prop, value) {
      this.$emit("stepperInput", {
        prop: prop,
        value: value
      });
    }
  }
};
</script>
