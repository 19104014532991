<template>
  <div>
    <b-row>
      <StockInfoPanels
        v-if="!firstLoader"
        :fromStoreName="selectedFromStore.name"
        :toStoreName="selectedToStore.name"
      >
      </StockInfoPanels>
      <b-col sm="12">
        <StockPurchaseOrderPreviewItemsTable
          :headers="headers"
          :items="selectedItems"
          :showTotalPrice="showTotalPrice"
        >
        </StockPurchaseOrderPreviewItemsTable>
      </b-col>
      <b-col sm="12">
        <v-textarea
          name="receiving-notes"
          :label="$t('NOTES')"
          auto-grow
          rows="1"
          prepend-icon="mdi-comment"
          v-model="tempNotes"
        ></v-textarea>
      </b-col>
    </b-row>
    <div class="mt-3">
      <b-button
        :disabled="false"
        class="mr-3"
        variant="primary"
        @click="onSave"
        >{{ $t("SAVE") }}</b-button
      >
      <b-button
        class="mr-3"
        variant="outline-primary"
        @click="updateWizardStep(2)"
        >{{ $t("BACK") }}</b-button
      >
    </div>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
import { mapGetters, mapState } from "vuex";
import StockInfoPanels from "@/modules/stock/components/StockInfoPanels.vue";
import StockPurchaseOrderPreviewItemsTable from "@/modules/stock/components/purchase-orders/StockPurchaseOrderPreviewItemsTable.vue";

import { STOCK_PURCHASE_ORDER_CREATE } from "@/modules/stock/store/stock.module";

import AuthService from "@/core/services/auth.service";

import _ from "lodash";
import moment from "moment";

export default {
  name: "StepStockPurchaseOrderPreview",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    wizardStep: {
      type: Number,
      default: 1
    },
    notes: {
      type: String,
      default: ""
    },
    selectedItems: {
      type: Array,
      default: function() {
        return [];
      }
    },
    doGetItems: {
      type: Boolean,
      default: true
    },
    headers: {
      type: Array,
      default: function() {
        return [];
      }
    },
    selectedItemsTotal: {
      type: Number,
      default: 0
    },
    selectedFromStore: {
      type: Object,
      default: function() {
        return {};
      }
    },
    selectedToStore: {
      type: Object,
      default: function() {
        return {};
      }
    },
    showTotalPrice: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      forDate: moment().format("YYYY-MM-DD")
    };
  },
  components: {
    StockInfoPanels,
    StockPurchaseOrderPreviewItemsTable
  },
  beforeMount() {},
  mounted() {},
  computed: {
    ...mapGetters(["isLoadingStock", "isLoadingSharedStore", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempNotes: {
      get: function() {
        return this.notes;
      },
      set: function(newValue) {
        this.$emit("update:notes", newValue);
      }
    },
    tempDoGetItems: {
      get: function() {
        return this.doGetItems;
      },
      set: function(newValue) {
        this.$emit("update:doGetItems", newValue);
      }
    },
    maxDate() {
      return moment().format("YYYY-MM-DD");
    },
    payload() {
      let vm = this;
      let payload = {};
      payload.fromStoreId = vm.selectedFromStore.id;
      payload.toStoreId = vm.selectedToStore.id;
      payload.action_notes = vm.tempNotes;
      payload.items = vm.preparePayloadItems();
      return payload;
    }
  },
  watch: {},
  methods: {
    preparePayloadItems() {
      let vm = this;
      let items = [];
      vm.selectedItems.forEach(item => {
        let singleItem = {
          itemId: item.id,
          unitId: item.unitId,
          unitValue: item.stockPurchaseOrderRequestedItem.unitValue
        };
        items = [...items, singleItem];
      });
      return items;
    },
    onSave() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          let payload = vm.payload;
          vm.$store
            .dispatch(STOCK_PURCHASE_ORDER_CREATE, payload)
            .then(data => {
              vm.errors = data.data.errors;
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              vm.tempDoGetItems = true;
              localStorage.removeItem("stockPurchaseOrderSelectedItems");
              vm.updateWizardStep(1);
              //Refresh Badges, after SAVE, otherwise the refresh is 1 minute
              AuthService.refreshUserData();
              vm.$router.push({ name: "stock-purchase-orders-list" });
            })
            .catch(function(response) {
              vm.errors = response.data.errors;
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    updateWizardStep(wizardStep) {
      this.$emit("update:wizardStep", wizardStep);
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    handleInput(prop, value) {
      this.$emit("stepperInput", {
        prop: prop,
        value: value
      });
    }
  }
};
</script>
