<template>
  <div>
    <b-row>
      <b-col :sm="6">
        <v-autocomplete
          v-if="_.size(stores) > 0 && _.size(stores) > 0"
          :items="stores"
          :label="$t('FROM_STORE')"
          item-text="name"
          item-value="id"
          clearable
          v-model="selectedFromStoreId"
          v-on:change="doSelectFromStore"
        ></v-autocomplete>
      </b-col>
      <b-col :sm="6">
        <v-autocomplete
          v-if="_.size(fullStores) > 0 && _.size(fullStores) > 0"
          :items="fullStores"
          :label="$t('TO_STORE')"
          item-text="name"
          item-value="id"
          clearable
          v-model="selectedToStoreId"
          v-on:change="doSelectToStore"
        ></v-autocomplete>
      </b-col>
    </b-row>
    <b-button
      :disabled="
        selectedFromStoreId == null ||
          selectedToStoreId == null ||
          selectedFromStoreId == selectedToStoreId
      "
      class="mr-3"
      variant="primary"
      @click="goNextStep()"
      >{{ $t("CONTINUE") }}</b-button
    >
  </div>
</template>
<script>
import { FETCH_STORES_BASIC } from "@/core/services/store/shared_store.module";
import { FETCH_REQUESTABLE_ITEMS_BY_STORE } from "@/modules/stock/store/stock.module";

import { mapGetters, mapState } from "vuex";

import _ from "lodash";
export default {
  name: "StepStockPurchaseOrderSelectStore",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    doGetItems: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    },
    localStorageSelectedItems: {
      type: Array,
      default: function() {
        return [];
      }
    },
    wizardStep: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      stores: [],
      fullStores: [],
      selectedFromStoreId: null,
      selectedToStoreId: null
    };
  },
  components: {},
  mounted() {
    let vm = this;
    vm.getStores();
    vm.getFullStores();
  },
  computed: {
    ...mapGetters(["isLoadingStock", "isLoadingSharedStore", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempItems: {
      get: function() {
        return this.items;
      },
      set: function(newValue) {
        this.$emit("update:items", newValue);
      }
    },
    tempDoGetItems: {
      get: function() {
        return this.doGetItems;
      },
      set: function(newValue) {
        this.$emit("update:doGetItems", newValue);
      }
    },
    selectedFromStore() {
      let vm = this;
      return _.find(vm.stores, { id: vm.selectedFromStoreId });
    },
    selectedToStore() {
      let vm = this;
      return _.find(vm.fullStores, { id: vm.selectedToStoreId });
    },
    storesParams() {
      let storesParams = {};
      return storesParams;
    },
    fullStoresParams() {
      let fullStoresParams = { full: 1 };
      return fullStoresParams;
    },
    itemsParams() {
      let vm = this;
      let itemsParams = {
        storeId: vm.selectedFromStoreId
      };
      return itemsParams;
    }
  },
  watch: {
    storesParams(value) {
      let vm = this;
      vm.getStores();
    },
    fullStoresParams(value) {
      let vm = this;
      vm.getFullStores();
    }
  },
  methods: {
    getStores() {
      let vm = this;
      let params = this.storesParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_STORES_BASIC, apiParams)
        .then(data => {
          this.$set(vm, "stores", data.data);
          if (_.size(data.data) == 1) {
            //Ако е само един магазин, да вземе първия, че да не се налага да избират 1 от 1
            this.$set(vm, "selectedFromStoreId", data.data[0].id);
          } else {
            //Ако е някой админ и управлява повече от 1 магазин, да може да избира между всички негови
            this.$set(vm, "selectedFromStoreId", null);
          }
          this.doSelectFromStore();
          vm.tempFirstLoader = false;
        })
        .catch(response => {
          console.log(response);
        });
    },
    getFullStores() {
      let vm = this;
      let params = this.fullStoresParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_STORES_BASIC, apiParams)
        .then(data => {
          this.$set(vm, "fullStores", data.data);
          this.$set(vm, "selectedToStoreId", null);
          // this.doSelectToStore();
          vm.tempFirstLoader = false;
        })
        .catch(response => {
          console.log(response);
        });
    },
    getItems() {
      let vm = this;
      let params = this.itemsParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_REQUESTABLE_ITEMS_BY_STORE, apiParams)
        .then(data => {
          // Check if selectedItems are stored in local storage
          if (vm.localStorageSelectedItems.length > 0) {
            // Iterate through data.data and update unitValue and packageValue from the selectedItems
            data.data.forEach(warehouse => {
              warehouse.items.forEach(item => {
                const selectedItem = vm.localStorageSelectedItems.find(
                  selectedItem => selectedItem.id === item.id
                );
                if (selectedItem) {
                  item.stockPurchaseOrderRequestedItem.unitValue =
                    selectedItem.stockPurchaseOrderRequestedItem.unitValue;
                  item.stockPurchaseOrderRequestedItem.packageValue =
                    selectedItem.stockPurchaseOrderRequestedItem.packageValue;
                }
              });
            });
            console.log(data.data);
          }
          vm.tempItems = data.data;
          vm.tempDoGetItems = false; //prevent reload same items if selectStore is not changed
          this.updateWizardStep(2);
        })
        .catch(response => {
          console.log(response);
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    doSelectFromStore() {
      let vm = this;
      vm.tempDoGetItems = true;
      this.$emit("update:selectedFromStore", vm.selectedFromStore);
    },
    doSelectToStore() {
      let vm = this;
      vm.tempDoGetItems = true;
      this.$emit("update:selectedToStore", vm.selectedToStore);
    },
    goNextStep() {
      let vm = this;
      if (vm.tempDoGetItems == true) {
        this.getItems();
      } else {
        this.updateWizardStep(2);
      }
    },
    updateWizardStep(wizardStep) {
      this.$emit("update:wizardStep", wizardStep);
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    handleInput(prop, value) {
      this.$emit("stepperInput", {
        prop: prop,
        value: value
      });
    }
  }
};
</script>
